import axios from 'axios'
import {createBrowserHistory} from 'history';

const instance = axios.create({
	baseURL: 'https://kangoru-inventario-api.mimodamia.com',
})

instance.interceptors.response.use(
    function (response) { 
        return response; 
    }, 
    function (error) { 
        if (401 === error.response.status) { 
            //window.location.replace('login');
           createBrowserHistory().push('/login');
           window.location.reload();
        }

	return Promise.reject(error);
    });

instance.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
instance.defaults.headers.common['X-Frontend-Domain'] = window.location.hostname.replace('www.','');

export { instance as axios };
